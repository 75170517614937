import { colors } from '../../Core/Theme/theme';

const styles = () => ({
  inputLabel: {
    display: 'block',
    fontSize: '.8em',
    color: '#545454',
  },
  input: {
    display: 'block',
    width: '100%',
    fontSize: '.9em',
    padding: '25px 10px 25px 10px',
    borderRadius: 10,
    borderColor: colors.lightGray,
    borderWidth: 1,
    borderStyle: 'solid',
    margin: '5px 0 20px',
    height: 38,
    lineHeight: 38,
  },
  password: {
    fontSize: '2em',
    letterSpacing: 8,
    paddingLeft: 15,
  },
  inputMultiline: {
    height: '6em',
  },
  inputError: {
    marginBottom: 5,
    borderColor: '#dd0000',
  },
  error: {
    fontSize: '.8em',
    color: '#dd0000',
    marginBottom: 20,
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
  required: {
    '&::after': {
      content: '"*"',
      color: colors.green,
    },
  },
  checkboxContainer: {
    position: 'relative',
    cursor: 'pointer',
    '& span': {
      fontSize: '.8em',
      margin: 0,
    },
    '& > label': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  checkbox: {
    display: 'inline-flex',
    width: 30,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.orange,
    border: `1px solid ${colors.lightGray}`,
    borderRadius: 10,
  },
  left: {
    order: -1,
    marginRight: 10,
  },
  right: {
    order: 1,
    marginLeft: 10,
  },
  checkboxIcon: {
    position: 'absolute',
  },
  ccContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '5px 0 20px',
  },
  ccField: {
    flexGrow: 1,
    margin: 0,
  },
  cc: {
    width: 75,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ccImg: {
    maxWidth: '45px',
    maxHeight: '35px',
  },
  dropdownContainer: {
    position: 'relative',
  },
  dropdownControl: {
    margin: `5px 0px`,
    width: '100%',
  },
  dropdownArrow: {
    color: `${colors.green}`,
    marginRight: 20,
  },
  dropdown: {
    borderRadius: 10,
    '& div': {
      height: 23,
      paddingRight: '10px !important',
    },
  },
  dropdownError: {
    border: `1px solid ${colors.green}`,
  },
});

export default styles;
