const styles = () => ({
  pageBox: {
    root: { backgroundColor: '#fff' },
    backgroundColor: '#fff',
    padding: 0,
    maxWidth: 1200,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  bg: {
    width: '60%',
    objectFit: 'cover',
    height: 'auto',
  }
});

export default styles;
