import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { theme as t } from './Core/Theme/theme';
import Layout from './Containers/Layout/Layout';

const App = () => {
  return (
    <MuiThemeProvider theme={t}>
      <Layout />
    </MuiThemeProvider>
  );
}

export default App;
