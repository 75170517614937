import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: 'json',
});

const validateCedPadron = _cedula => {
  return axiosInstance.get(`
    /padron/validateCedula/${_cedula}`);
};

const validateAdhesionByCed = _cedula => {
  return axiosInstance.get(
    `/cooperativas/validateAdhesion/${_cedula}`,
  );
};

const saveAdhesion = _info => {
  return axiosInstance.post(
    '/cooperativas/saveAdhesion',
    _info,
  );
};

export default {
  validateOnPadron: validateCedPadron,
  saveNewAdhesion: saveAdhesion,
  validateAdhesion: validateAdhesionByCed,
};
