/* eslint-disable camelcase */
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

/* eslint-disable import/prefer-default-export */
export const searchByCedula = cedula => {
  const url = [`/padron/searchbycedula/${cedula}`];

  return axios.get(url.join('&'));
};

export const updatePersonInfo = params => {
  const {
    num_cedula,
    ind_sexo,
    telefonosData,
    direccionesData,
    emailsData,
  } = params;

  const personInfo = {
    num_cedula,
    ind_sexo,
    telefonos: telefonosData,
    direcciones: direccionesData,
    emails: emailsData,
  };

  return axios.post('/padron/updatePersonInfo', personInfo);
};

export const validateCedulaPadron = cedula => {
  return axios.get(`/padron/validateCedula/${cedula}`);
}

export const updatePersonPhone = params => {
  const { cedula, telefonos } = params;

  const data = {
    num_cedula: cedula,
    telefonos,
  };

  return axios.post('/padron/updateTelefonos', data);
};

export const updatePersonEmail = params => {
  const { cedula, email } = params;

  const data = {
    num_cedula: cedula,
    email,
  };

  return axios.post('/padron/updateEmail', data);
};
