import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import style from './styles';

const MainPage = props => {
  const { classes } = props;

  MainPage.propTypes = {};

  return (
    <>
      <Container
        maxWidth={false}
        className={classes.pageBox}
      >
        <img alt="bg-main-page" src="./aviso.jpg" className={classes.bg}/>
      </Container>
    </>
  );
};

export default withStyles(style)(MainPage);
