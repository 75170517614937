import MainPage from '../Containers/MainPage/MainPage';
import AddAdhesion from '../Containers/Adhesiones/AddAdhesion';

const routesList = [
  {
    auth: false,
    component: MainPage,
    exact: true,
    name: 'mainPage',
    path: '/',
  },
  {
    auth: false,
    component: AddAdhesion,
    exact: true,
    name: 'addCooperativista',
    path: '/add-cooperativista',
    options: {
      hideHeader: true
    }
  },
];

export default routesList;
