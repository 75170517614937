import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container, Grid, Link } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import style from './styles';

const Header = props => {
  // eslint-disable-next-line react/prop-types
  const { classes, hideHeader } = props;

  const renderOptions = () => {
    let template = (
      <ul className={classes.loginAccess}>
        <li>
          <NoteAddIcon />
          <Link href="/add-cooperativista">
            <span>Formulario de Adhesión</span>
          </Link>
        </li>
      </ul>
    );

    if (hideHeader) template = <></>;

    return template;
  };

  return (
    <Container maxWidth={false}>
      <Grid container>
        <Grid item xs={6} className={classes.logoParent}>
          <div className={classes.logoContainer}>
            <img
              src="/logo.png"
              className={classes.imgLogo}
              alt="CooperativistaLogo"
            />
          </div>
          <h1
            style={{ color: '#444', fontFamily: 'Arial' }}
          >
            Adhesiones para Cooperativistas
          </h1>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          {renderOptions()}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <AppBar
          position="static"
          style={{
            backgroundColor: '#FFC926',
            height: '5px',
          }}
        >
          &nbsp;
        </AppBar>
        <AppBar position="static">&nbsp;</AppBar>
      </Grid>
    </Container>
  );
};

export default withStyles(style)(Header);
